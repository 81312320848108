import React from 'react';
import styled from 'styled-components';

import Masthead from '../components/masthead';

const StyledClaims = styled.div`
	background-color: ${(p) => p.theme.color.orange};
	padding: 3rem ${(p) => p.theme.gutter};
	h2 {
		font-size: 1.5rem;
		line-height: 2rem;
		font-weight: 700;
		margin-bottom: 1rem;
	}
	.claims-container {
		display: flex;
		flex-wrap: wrap;

		.claim {
			width: 50%;
			border-top: 2px solid rgba(255, 255, 255, 0.25);
			padding: 1.25rem 0;

			&:nth-child(2n) {
				padding-left: 2rem;
			}
			&:nth-child(2n-1) {
				border-right: 2px solid rgba(255, 255, 255, 0.25);
				&:last-child {
					border-right: none;
				}
			}

			h3 {
				font-size: 1.3rem;
				line-height: 1rem;
				color: #fff;
				font-family: ${(p) => p.theme.fonts.title};
			}
			a {
				color: #fff;
				font-size: 1.5rem;
				line-height: 2.3rem;
				font-family: ${(p) => p.theme.fonts.title};
				position: relative;
				&::after {
					content: '';
					background-color: #fff;
					height: 1px;
					width: 100%;
					left: 0;
					bottom: 0;
					position: absolute;
					opacity: 0;
					transition: 0.5s;
				}
				&:hover::after {
					opacity: 1;
				}
			}
			.other {
				p {
					color: #fff;
					font-size: 0.9rem;
					line-height: 0.9rem;
				}
			}
		}
	}

	@media all and (min-width: 1200px) {
		padding: 2rem ${(p) => p.theme.gutterWide};
	}
	@media all and (min-width: 1600px) {
		padding: 6rem ${(p) => p.theme.gutterXWide};
		h2 {
			font-size: 2rem;
			line-height: 2.5rem;
		}
		.claims-container .claim {
			h3 {
				font-size: 1.5rem;
				line-height: 1.5rem;
			}
			a {
				font-size: 1.7rem;
				line-height: 2.5rem;
			}
			.other p {
				font-size: 1.1rem;
				line-height: 1.3rem;
			}
		}
	}
	@media all and (max-width: 668px) {
		.claims-container .claim {
			border-right: none !important;
			padding-left: 0 !important;
			width: 100%;
		}
	}
	@media all and (max-width: 500px) {
		padding: 2rem ${(p) => p.theme.gutterMobile};
	}
`;

export default ({ data }) => {
	const { banner, main } = data.allMarkdownRemark.nodes[0].frontmatter;

	// Change ordering of numbers list so alphabetization
	//  flows down each column.
	const orderedNumbers = [];
	let i = 0;
	while (i < main.numbers.length / 2) {
		let col1 = main.numbers[i];
		let col2 = main.numbers[Math.round(main.numbers.length / 2) + i];

		orderedNumbers.push(col1);
		if (typeof col2 !== 'undefined') orderedNumbers.push(col2);
		i++;
	}

	return (
		<>
			<Masthead title={banner.title} image={banner.img} />
			<StyledClaims id="claims">
				<h2>{main.title}</h2>
				<div className="claims-container">
					{orderedNumbers.map((item, index) => (
						<div className="claim" key={index}>
							<h3>{item.name}</h3>
							<a href={item.phone.link}>{item.phone.text}</a>
							{item.other && (
								<div className="other">
									<p>{item.other.title}</p>
									<a href={item.other.phone.link}>{item.other.phone.text}</a>
								</div>
							)}
						</div>
					))}
				</div>
			</StyledClaims>
		</>
	);
};
