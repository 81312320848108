import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import ClaimsTemplate from '../templates/claims-template';
import SEO from '../components/seo';

const ClaimsPage = ({ data }) => {
	return (
		<Layout page="Claims">
			<SEO
				title="Claims"
				description={data.allMarkdownRemark.nodes[0].frontmatter.description}
			/>
			<ClaimsTemplate data={data} />
		</Layout>
	);
};

export default ClaimsPage;

export const query = graphql`
	{
		allMarkdownRemark(filter: { frontmatter: { slug: { eq: "claims" } } }) {
			nodes {
				frontmatter {
					description
					banner {
						title
						img
					}
					main {
						title
						numbers {
							name
							phone {
								text
								link
							}
							other {
								title
								phone {
									text
									link
								}
							}
						}
					}
				}
			}
		}
	}
`;
